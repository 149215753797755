<template>
	<main-container>
		<el-timeline>
			<template v-for="item in list">
				<el-timeline-item :timestamp="item|date" placement="top">
					<el-card>
						<div>{{item|content}}</div>
						<small>Tag: {{ item }}</small>
					</el-card>
				</el-timeline-item>
			</template>
		</el-timeline>
	</main-container>
</template>

<script>
	export default {
		data() {
			return {
				tags: `
					ads-v2-2021-11-26-1635-account-config
					ads-v2-2021-12-07-1827-single-page
					ads-v2-2021-12-07-1927-fixed-bug-ads-list-stat
					ads-v2-2021-12-09-0933-non-distincted-products
					ads-v2-2021-12-10-1400-multiple-table-check
					ads-v2-2021-12-13-0952-fixed-bug-create-category
					ads-v2-2021-12-13-1032-fixeb-bug-singlespage-search
					ads-v2-2021-12-13-1058-fixed-bug-template-bind-value
					ads-v2-2021-12-14-1032-preview-multiple-ads-in-same-campaign
					ads-v2-2021-12-14-1407-fixed-bug-remove-block-spu
					ads-v3-2021-12-06-1007-content-config
					ads-v3-2021-12-06-1434-fixed-content
					ads-v3-2021-12-06-1833-remove-select-option
					ads-v3-2021-12-06-1846-remove-publish-type
					ads-v3-2021-12-31-1640-charts
					ads-v3-2021-12-31-1713-mock-login
					ads-v3-2021-12-31-1745-chart-css
					ads-v3-2021-12-31-2004-chart-header
					ads-v3-2021-12-31-2030-fixed-number
					ads-v3-2022-01-05-1920-feature-daily-report
					ads-v3-2022-01-06-1720-fixed-channel-localstorage
					ads-v3-2022-01-07-1540-feature-publish-records
					ads-v3-2022-01-10-1730-fixed-upload-component-headers
					ads-v3-2022-01-10-1930-improvement-republish-condition
					ads-v3-2022-01-12-1706-improvement-api-query-and-chart-yaxis-interval
					ads-v3-2022-01-12-1901-feature-token-cookie
					ads-v3-2022-01-13-1318-fixed-date-range-selection
					ads-v3-2022-01-13-1507-improvement-default-channel
					ads-v3-2022-01-13-1551-fixed-error-parse
					ads-v3-2022-01-17-1700-feature-export-records
					ads-v3-2022-02-10-1811-improvement-channel-saved
					ads-v3-2022-02-10-1843-improvement-date-time
					ads-v4-2022-02-14-1515-feature-sale-reports
					ads-v4-2022-02-14-1869-improvement-sale-page-querystring
					ads-v4-2022-02-15-1145-improvement-sale-page-websites-query
					ads-v4-2022-02-15-1530-fixed-bug-checkbox-context
					ads-v4-2022-02-16-1005-sale-records
					ads-v4-2022-02-16-1040-filter-categories
					ads-v4-2022-02-16-1420-sale-page-sortable
					ads-v4-2022-02-16-1440-sale-page-sort-method
					ads-v4-2022-02-16-1845-fixed-account-card-style
					ads-v4-2022-02-18-1335-improvement-page-size
					ads-v4-2022-02-19-1340-feature-tree-select-value
					ads-v4-2022-02-21-1733-feature-feed-tasks
					ads-v4-2022-02-21-1810-improvement-export-file-ext
					ads-v4-2022-02-22-1550-improvement-dialog-header
					ads-v4-2022-02-25-1845-cost-balance-update-at
					ads-v4-2022-02-28-1740-chart-newtest
					ads-v4-2022-03-08-1800-improvement-cost-page-deserialize
					ads-v4-2022-03-08-1834-fix-table-column-title
					ads-v4-2022-03-09-1810-fix-balance-display
					ads-v4-2022-03-11-1700-feature-roi
					ads-v4-2022-03-11-1705-feature-publishtype
					ads-v4-2022-03-11-1830-fixed-pervious-data
					ads-v4-2022-03-12-1730-feature-newtest
					ads-v4-2022-03-14-1105-improvement-newtest
					ads-v4-2022-03-18-1400-improvement-newtest
					ads-v5-2022-03-21-1944-improvement-sites-form-item
					ads-v5-2022-03-21-2017-fixed-newtest-empty-statistics-error
					ads-v5-2022-03-22-1005-fixed-non-abbr-channel
					ads-v5-2022-03-22-1035-fixed-context-default-publishtype
					ads-v5-2022-03-23-1505-improvement-today-available-in-chart
					ads-v5-2022-03-25-0924-fixed-default-channel
					ads-v5-2022-04-11-1553-feature_discount_for_echart
					ads-v5-2022-04-11-1623-Fixed_current_ratio_and_roi
					ads-v5-2022-04-22-1044-fix-ratio-roi
					ads-v5-2022-04-29-1911-feature-feed-channel
					ads-v5-2022-05-05-1747-dev-gg-uda-template
					ads-v5-2022-05-06-1423-improvement-echarts-loading
					ads-v5-2022-05-06-1642-dev-gg-uda-template
					ads-v5-2022-05-15-2125-feature-carousels-more-rules
					ads-v5-2022-05-16-1457-fixed-decimal-query-enum
					ads-v5-2022-05-16-1607-improvment-sync_status-enum
					ads-v5-2022-05-16-1635-fixed-sync_status-enum
					ads-v5-2022-05-17-1524-selection-page
					ads-v5-2022-05-20-1502-fixed-feed-content-category
					ads-v5-2022-05-25-2135-improvement-shopify-tools-api
					ads-v5-2022-05-25-2153-fixed-request-path
					ads-v5-2022-05-30-1058-improvment-skus
					ads-v5-2022-05-31-1024-improvment-lazy-mode
				`
			}
		},
		computed: {
			list() {
				const tags = this.tags.split('\n').map(v => v.trim()).filter(v => v !== '')
				tags.reverse()
				return tags
			}
		},
		filters: {
			date(v) {
				var timestamp = v.match(/^ads-v\d-(\d{4}-\d{2}-\d{2}-\d{4})-.*/)[1]
				const [year, month, day, hour, minute] = timestamp.match(/^(\d{4})-(\d{2})-(\d{2})-(\d{2})(\d{2})$/).splice(1, 5)
				return `${year}/${month}/${day} ${hour}:${minute}`
			},
			content(v) {
				var content = v.match(/^ads-v\d-\d{4}-\d{2}-\d{2}-\d{4}-(.*)/)[1]
				return content.replace(/-/g, ' ')
			}
		}
	}
</script>

<style>
</style>
